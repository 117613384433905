<template>
    <div class="frame">
        <div class="w-full" style="border-bottom: 1px solid #74c0fc">
            <div class="logo-redes flex lg:flex-row flex-column justify-content-between gap-3 w-11 md:w-10 lg:w-9 m-auto">
                <img @click="scrollToTop" class="group" alt="logo blue" src="../assets/img/group.png" style="cursor: pointer;"/>
                <div class="redes-socias flex lg:justify-content-between">
                    <div class="flex align-items-center justify-content-center">
                        <a class="mt-1" href="https://whatsapp.com/channel/0029VaBhuI73LdQZ6VsgvW0s" target="_blank">
                            <i class="pi pi-whatsapp text-2xl text-blue-600"></i>
                        </a>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <a class="mt-1" href="https://www.instagram.com/bluetechnology_/" target="_blank">
                            <i class="pi pi-instagram text-2xl text-blue-600"></i>
                        </a>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <a class="mt-" href="https://www.linkedin.com/company/blue-technology-brasil/mycompany/" target="_blank">
                            <img class="redes" alt="logo blue" src="../assets/img/linkedin.svg" />
                        </a>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <a class="mt-1" href="https://open.spotify.com/show/6OsGdipGOfEtKerCpBHeBR" target="_blank">
                            <img class="redes" alt="logo blue" src="../assets/img/group-60.png" />
                        </a>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <a class="mt-1" href="https://www.youtube.com/channel/UCnYmEmH0bpkNI83AHiKZ5Aw" target="_blank">
                            <img class="redes" alt="logo blue" src="../assets/img/group-61.png" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="frame-container m-auto w-11 lg:w-9 md:w-10 flex flex-column w-11 md:w-10 lg:w-9 m-auto">
            <div class="flex flex-column mt-7">
                <div class="blue-tech">Blue Technology</div>
                <div class="menu-navbar mt-3 mb-7 sm:mb-5 flex align-items-center">
                    <div class="navbar flex gap-6 w-max flex-wrap">
                        <router-link v-for="item in items" :key="item.id" class="text-wrapper flex-row w-max" :to="item.url">
                            {{ item.label }}
                        </router-link>
                    </div>
                </div>

                <div class="menu-navbar flex flex-column sm:flex-row flex-wrap gap-6">
                    <div>
                        <div class="end mb-4">Endereço</div>
                        <p class="endereco">Rua do Brum, 248, Sl 11 - 3º Andar <br />Bairro do Recife Antigo - Recife - PE<br />CEP 50.030-140</p>
                    </div>
                    <div>
                        <div class="end mb-4">Endereço</div>
                        <p class="endereco">
                            Rua Arnóbio Marques, 253<br />
                            24 andar - Empresarial Camilo Brito <br />
                            Bairro de Santo Amaro - Recife - PE
                        </p>
                    </div>
                </div>

                <div class="flex align-items-center flex-wrap gap-5 mt-7">
                    <a><img class="monday" alt="monday" src="../assets/img/monday.svg" /></a>
                    <a><img class="image" style="width: 156px; height: 56px" alt="sustentavel" src="../assets/img/image-11.png" /></a>
                    <a><img class="group" style="width: 206px; height: 41px" alt="logo blue" src="../assets/img/group-8.png" /></a>
                    <a><img class="great-place-to-work" alt="great place to work" src="../assets/img/great-place-to-work-2-1.svg" /></a>
                </div>
            </div>

            <div class="desenvolvido flex flex-column align-items-center gap-3 mb-6">
                <p>© Blue Technology</p>
                <div class="flex justify-content-evenly gap-3 flex-wrap">
                    <!-- <a href="/docs/BLUE_CÓDIGO_E_CONDUTA_EM_INTEGRIDADE_EMPRESARIAL- VERSÃO 00.pdf" download="Politica de privacidade"  style="font-size: 12px; color: #fff">Política de Privacidade</a> -->
                    <router-link :to="'/seguranca-da-Informacao'" style="font-size: 12px; color: #fff"
                        >Política de segurança da informação</router-link
                    >
                    <router-link :to="'/politica-de-direitos-do-titular-dos-dados'" style="font-size: 12px; color: #fff"
                        >Política de direitos de titular de dados</router-link
                    >
                    <a class="flex" href="/docs/POLITICA DE PROTEÇÃO DE DADOS EXTERNOS.pdf" download="Política de proteção de dados externos" style="font-size: 12px; color: #fff"
                        >Política de proteção de dados externos <i class="pi pi-file ml-2"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: 'AppFooter',
    components: {},
    data() {
        return {

            items: [
                {
                    label: 'Home',
                    url: '/',
                },
                {
                    label: 'Podcast',
                    url: '/podcast',
                },
                {
                    label: 'Na Mídia',
                    url: '/midia',
                },
                {
                    label: 'Canal de Integridade',
                    url: '/canal-integridade',
                },
                {
                    label: 'Contato',
                    url: '/contato',
                },
            ],
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
            router.push('/home');
        },
    },
};
</script>

<style scoped>
p {
    padding: 0;
    margin: 0;
}

.frame {
    margin-bottom: 1px solid black;
    height: auto;
    min-height: 830px;
    background: #1864ab;
    margin: 0 auto;
    .logo-redes {
        min-width: 1000px;
        padding: 56px 0;
        margin: 0 auto;
        .redes-socias {
            gap: 16px;
            cursor: pointer;
        }
        .group {
            width: 116.97px;
            height: 39px;
        }
        .redes-socias div {
            width: 50px;
            height: 50px;
            background: #E7F5FF;
            border-radius: 50%;
            .redes {
                width: 22px;
            }
        }
    }
}

.frame .frame-container {
    min-width: 1000px;
    height: 68%;
    margin: 0 auto;
}

.blue-tech {
    font-family: 'Khand';
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.menu-navbar {
    gap: 16px;
}

.navbar {
    width: 73%;
    gap: 16px;
    .text-wrapper {
        font-family: 'General Sans';
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;
        gap: 50px;
        cursor: pointer;
    }
}

.boto button {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #ffffff;
    background: #e7f5ff;
    color: #1864ab;
    font-family: 'General Sans';
    font-size: 16px;
    font-weight: 600;
    width: 186px;
    height: 46px;
}

.end {
    font-family: 'Khand';
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.endereco {
    font-family: 'General Sans';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.desenvolvido {
    font-family: 'General Sans';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-top: 125px;
}

@media screen and (max-width: 960px) {
    .frame {
        /* min-height: auto; */
        height: auto;
    }
    .frame .frame-container {
        min-width: auto;
    }
    .logo-redes {
        min-width: auto !important;
    }
    .criado {
        margin-top: 60px;
        margin-bottom: 20px;
    }
}
</style>
