<template>
    <div style="border-bottom: 1px solid #a7dbf7">
        <div class="frame flex lg:w-9 md:w-10 sm:w-11">
            <RouterLink to="Home">
                <img class="group" alt="divisoria de font" src="../assets/img/logo.png" />
            </RouterLink>
            <div class="card">
                <Menubar :model="items" style="background: none; border: none" :breakpoint="'1200px'">
                    <template  #item="{ item, props }">
                        <router-link v-if="item.url && !item.externo" v-slot="{ href, navigate }" :to="item.url" custom>
                            <a :href="href" v-bind="props.action" @click="navigate, reload" :class="{ rotaSelecionada: verificaAtivo(item) }">
                                <span class="ml-2 mr-2">{{ item.label }}</span>
                            </a>
                        </router-link>
                        <a class="p-menuitem-link" v-else :href="item.url" target="_blank" :class="{ rotaSelecionada: verificaAtivo(item) }">
                            <span class="ml-2">{{ item.label }}</span>
                        </a>
                    </template>
                </Menubar>
            </div>
                <btnAzul v-if="$mq === 'lg'" :msg="msgBtn" @clicado="entrarEmContato()" />
        </div>
    </div>
</template>

<script>
import router from '@/router';
import btnAzul from '../../src/layout/btnAzul.vue';
export default {
    name: 'AppHeader',
    components: {
        btnAzul,
    },
    data() {
        return {

            msgBtn: 'Entrar em contato',

            items: [
                {
                    label: 'Home',
                    url: '/home',
                },
                { separator: true },
                {
                    label: 'SAAS',
                    url: 'https://bluetechnology.rds.land/gestao360',
                    externo: true,
                },
                { separator: true },
                {
                    label: 'PodCast',
                    url: '/podcast',
                },
                { separator: true },
                {
                    label: 'Na Mídia',
                    url: '/midia',
                },
                { separator: true },
                {
                    label: 'Canal de Integridade',
                    url: '/canal-integridade',
                },
                { separator: true },
                {
                    label: 'Trabalhe Conosco',
                    url: 'https://bluetechnology.vagas.solides.com.br/',
                    externo: true,

                }
            ],
        };
    },
    methods: {
        entrarEmContato() {
            router.push('/contato');
        },
        verificaAtivo(item) {
            return item.url === this.$route.path;
        },
        reload(){
            if(this.$route.path === '/midia'){
                window.location.reload()
            }
        }
    },
};
</script>

<style scoped>
.rotaSelecionada {
    color: #1864ab !important;
    font-weight: 600;
}
::v-deep .p-menuitem-separator {
    border: 1px solid #a5d8ff;
    height: 20px;
}

::v-deep .p-menubar .p-menuitem-link {
    cursor: pointer;
}

::v-deep .p-menubar .p-menuitem-link:hover {
    font-weight: 600 !important;
}
.frame {
    min-width: 100px;
    margin: 0 auto;
    height: 76px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .group {
        width: 84px;
        cursor: pointer;
    }
}

button:focus-visible {
    outline: 2px solid #4a90e2 !important;
    outline: -webkit-focus-ring-color auto 5px !important;
}

@media (max-width: 1200px) {
    ::v-deep .p-menuitem-separator {
        display: none;
    }
    ::v-deep .p-menubar-root-list {
        width: max-content;
    }
    .frame{
         flex-direction: row-reverse;
    }
}

@media screen and (min-width: 1160px) {
    .frame {
        min-width: 1150px;
        display: flex;
    }
}
</style>
