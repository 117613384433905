<template>
    <div class="flex gap-5">
        <button class="btn-contatos bg-blue-700"
            @click="$emit('clicado')"
            :disabled="disabled"
        >
        <span>{{ msg }}</span></button>
    </div>
</template>

<script>
export default {
    name: 'AppBtnAzul',
    components: {},

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        msg: {
            type: String,
            required: true,
        },
    },

    data() {
        return {};
    },

    methods: {},
};
</script>

<style>
.btn-contatos {
    min-height: 48px;
    height: auto;
    border: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    padding: 12px 24px;
    font-family: 'General Sans';
    color: #e7f5ff;
    cursor: pointer;
}
.btn-contatos:hover {
    background: #3680E4 !important;
}
</style>