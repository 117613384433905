import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../components/home/AppHome.vue'),
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            breadcrumb: ['HOME'],
        },
        component: () => import('../components/home/AppHome.vue'),
    },
    {
        path: '/midia',
        name: 'AppMidia',
        component: () => import('../components/midia/AppMidia.vue'),
    },
    {
        path: '/podcast',
        name: 'AppPodcast',
        component: () => import('../components/podcast/AppPodccast.vue'),
    },
    {
      path: '/saas',
      name: 'AppSaas',
      component: () => import('../components/saas/AppSaas.vue')
    },
    {
        path: '/canal-integridade',
        name: 'AppCanalIntegridade',
        component: () => import('../components/canalIntegridade/AppCanalIntegridade.vue'),
    },
    {
        path: '/contato',
        name: 'AppContato',
        component: () => import('../components/contato/AppContato.vue'),
    },
    {
        path: '/seguranca-da-Informacao',
        name: 'SegurancaInformacao',
        component: () => import('../components/politicas/SegurancaInformacao.vue'),
        
    },
    {
        path: '/politica-de-direitos-do-titular-dos-dados',
        name: 'PoliticaTitularDados',
        component: () => import('../components/politicas/DireitoTitularDados.vue'),
    },
    {
        path: '/servicos',
        name: 'Servicos',
        component: () => import('../components/servicos/Servicos.vue'),
    },
    {
        path: '/linha-de-frente',
        name: 'LinhaFrente',
        component: () => import('../components/time/Time.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

export default router;
