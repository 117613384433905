<template>
  <Toast />
  <AppHeader/>
  <router-view></router-view>
  <AppFooter/>
</template>

<script>

import AppHeader from './layout/AppHeader.vue'
import AppFooter from './layout/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  }
}

</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
    font-family: 'General Sans';
    src: url(./assets/fonts/GeneralSans-Regular.otf);
}

@import url('https://fonts.googleapis.com/css?family=Khand:400');

a {text-decoration: none;}

body {
  background-color: #fff;
}

</style>
