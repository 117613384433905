import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import Menubar from 'primevue/menubar';
import VueMq from 'vue3-mq'
import InputMask from 'primevue/inputmask';
//SweetAlert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Carousel from 'primevue/carousel';
import { Slide } from 'vue3-carousel';

import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/lara-light-green/theme.css'
import router from './router';

const app = createApp(App)

app.component('Menubar', Menubar);
app.component('InputMask', InputMask);
app.component('Toast', Toast);
app.component('InputText', InputText);
app.component('Textarea', Textarea);
app.component('Card', Card);
app.component('Button', Button);
app.component('Carousel', Carousel);
app.component('Slide', Slide);

app.use(PrimeVue, { unstyled: false });
app.use(router);
app.use(VueSweetalert2);
app.use(ToastService);


app.use(VueMq, {
    breakpoints: {
        sm: 576,
        md: 959,
        bp: 1200,
        lg: Infinity,
    }
});

app.mount('#app')
